import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useQuery } from '@tanstack/react-query';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Tabs,
    Tab,
    Box,
    TablePagination,
    Button,
    IconButton,
    TextField,
    MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Skill, SkillType } from '../../interfaces/rest';
import AddSkillForm from './addSkillForm';
import { fetchSkills, saveSkill, deleteSkill } from './skillApi';

interface GlossaryTableProps {
    isSalesUser: boolean;
}

export default function GlossaryTable({
    isSalesUser,
}: GlossaryTableProps): React.ReactElement {
    const [skills, setSkills] = useState<Skill[]>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [editingSkill, setEditingSkill] = useState<Skill | null>(null);
    const [editName, setEditName] = useState<string>('');
    const [editDescription, setEditDescription] = useState<string>('');
    const [editPriority, setEditPriority] = useState<number>(3);
    const [duplicateError, setDuplicateError] = useState<string | null>(null);
    const auth = useAuth();
    const skillTypes: SkillType[] = ['IT', 'LANGUAGE', 'CONSULTING'];

    const {
        isPending: isPendingGet,
        isError: isErrorGet,
        error: errorGet,
    } = useQuery({
        queryKey: ['glossaryGet'],
        queryFn: async () => {
            const fetchedSkills = await fetchSkills(
                auth.user?.access_token || ''
            );
            fetchedSkills.sort((a, b) => a.name.localeCompare(b.name));
            setSkills(fetchedSkills);
            return fetchedSkills;
        },
    });

    if (isPendingGet) return <div>Loading glossary data...</div>;
    if (isErrorGet) return <div>An error has occurred: {errorGet.message}</div>;

    const filteredSkills = (type: SkillType) =>
        skills.filter(skill => skill.type === type);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displaySkills = (type: SkillType) =>
        filteredSkills(type).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );

    const toggleFormVisibility = () => {
        setFormVisible(!formVisible);
    };

    const handleAddSkill = (newSkill: Skill) => {
        const updatedSkills = [...skills, newSkill];
        updatedSkills.sort((a, b) => a.name.localeCompare(b.name));
        setSkills(updatedSkills);

        const tabIndex = skillTypes.indexOf(newSkill.type);
        if (tabIndex >= 0) {
            setSelectedTab(tabIndex);
        }
    };

    const handleEditSkill = (skill: Skill) => {
        setDuplicateError(null);
        setEditingSkill(skill);
        setEditName(skill.name);
        setEditDescription(skill.description);
        setEditPriority(skill.priority ?? 3);
    };

    const isSkillNameUnique = (name: string, id?: number) => {
        return !skills.some(
            skill =>
                skill.name.toLowerCase() === name.toLowerCase() &&
                skill.id !== id
        );
    };

    const handleSaveSkill = async () => {
        if (!editingSkill) return;

        if (!isSkillNameUnique(editName, editingSkill.id)) {
            setDuplicateError('A skill with this name already exists.');
            return;
        }

        const updatedSkill = {
            ...editingSkill,
            name: editName,
            description: editDescription,
            priority: editPriority,
        };

        try {
            await saveSkill(auth.user?.access_token || '', updatedSkill);
            const updatedSkills = skills.map(skill =>
                skill.id === editingSkill.id ? updatedSkill : skill
            );
            setSkills(updatedSkills);
            setEditingSkill(null);
            setDuplicateError(null);
            setEditPriority(3);
        } catch (error) {
            console.error('Failed to update the skill', error);
        }
    };

    const handleDeleteSkill = async (skillId: number) => {
        try {
            await deleteSkill(auth.user?.access_token || '', skillId);
            const updatedSkills = skills.filter(skill => skill.id !== skillId);
            setSkills(updatedSkills);
        } catch (error) {
            console.error('Failed to delete the skill', error);
        }
    };
    // prettier-ignore
    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography variant='h4' gutterBottom align='center'>
                Glossar
            </Typography>
            <Tabs value={selectedTab} onChange={handleChangeTab} indicatorColor='primary' textColor='primary' centered>
                {skillTypes.map(type => (
                    <Tab key={type} label={type} />
                ))}
            </Tabs>
            <Box sx={{ paddingTop: 4 }}>
                <Button variant='contained' color='primary' onClick={toggleFormVisibility}>
                    {formVisible ? 'Hide Form' : 'Add New Skill'}
                </Button>
                {formVisible && (
                    <Box sx={{ marginTop: 2 }}>
                        <AddSkillForm onAddSkill={handleAddSkill} existingSkills={skills} />
                    </Box>
                )}
                <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 3, marginTop: 2 }}>
                    <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label='glossary table'>
                        <TableHead sx={{ bgcolor: '#f5f5f5' }}>
                            <TableRow>
                                <TableCell sx={{ width: '30%' }}>
                                    <strong>Name</strong>
                                </TableCell>
                                {isSalesUser && (
                                <TableCell sx={{ width: '15%' }}>
                                    <strong>Priority</strong>
                                </TableCell>)}
                                <TableCell sx={{ width: '55%' }}>
                                    <strong>Description</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {skillTypes[selectedTab] &&
                                displaySkills(skillTypes[selectedTab]).map(skill => (
                                    <TableRow
                                        key={skill.id}
                                        hover
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        <TableCell>
                                            {editingSkill?.id === skill.id ? (
                                                <TextField
                                                    value={editName}
                                                    onChange={e => setEditName(e.target.value)}
                                                    size='small'
                                                    error={!!duplicateError}
                                                    helperText={duplicateError}
                                                />
                                            ) : (
                                                skill.name
                                            )}
                                        </TableCell>
                                        {isSalesUser && (
                                        <TableCell>
                                            {editingSkill?.id === skill.id ? (
                                                <TextField
                                                label='Priority (Optional)'
                                                variant='outlined'
                                                value={editPriority}
                                                onChange={(e) => setEditPriority(parseInt(e.target.value))}
                                                select
                                                size='small'
                                            >
                                                {[1, 2, 3, 4, 5].map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            ) : (
                                                skill.priority ?? 3
                                            )}
                                        </TableCell>)}
                                        <TableCell sx={{ position: 'relative' }}>
                                            {editingSkill?.id === skill.id ? (
                                                <TextField
                                                    value={editDescription}
                                                    onChange={e => setEditDescription(e.target.value)}
                                                    size='small'
                                                />
                                            ) : (
                                                skill.description
                                            )}
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: 8,
                                                    transform: 'translateY(-50%)',
                                                    opacity: 0,
                                                    transition: 'opacity 0.2s',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    '&:hover': {
                                                        opacity: 1,
                                                    },
                                                }}
                                                className='actions'
                                            >
                                                {editingSkill?.id === skill.id ? (
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={handleSaveSkill}
                                                        size='small'
                                                    >
                                                        Save
                                                    </Button>
                                                ) : (
                                                    <>
                                                        <IconButton
                                                            color='primary'
                                                            onClick={() => handleEditSkill(skill)}
                                                            size='small'
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            color='secondary'
                                                            onClick={() => handleDeleteSkill(skill.id)}
                                                            size='small'
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component='div'
                        count={filteredSkills(skillTypes[selectedTab]).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
        </Container>
    );
}
