import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Select,
    MenuItem,
    Box,
} from '@mui/material';
import { FullEmployee, ContractorMapping } from '../../interfaces/rest';
import {
    getMappingRef,
    MappingRef,
} from '../../interfaces/contractorMappingRef';

interface EmployeeExportTableProps {
    employees: FullEmployee[];
    mappings: ContractorMapping[];
    onExport: (employeeId: number, mappingRef: MappingRef) => void;
}

const EmployeeExportTable: React.FC<EmployeeExportTableProps> = ({
    employees,
    mappings,
    onExport,
}) => {
    const [mappingSelections, setMappingSelections] = React.useState<{
        [employeeId: number]: MappingRef;
    }>({});

    const handleMappingChange = (
        employeeId: number,
        mappingRef: MappingRef
    ) => {
        setMappingSelections(prevSelections => ({
            ...prevSelections,
            [employeeId]: mappingRef,
        }));
    };

    const handleExportClick = (employeeId: number) => {
        const selectedMapping = mappingSelections[employeeId];
        if (!selectedMapping) {
            alert('Bitte Mapping auswählen.');
            return;
        }
        onExport(employeeId, selectedMapping);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Vorname</TableCell>
                    <TableCell>Nachname</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Export</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {employees.map((employee: FullEmployee) => (
                    <TableRow key={employee.id}>
                        <TableCell>{employee.id}</TableCell>
                        <TableCell>{employee.firstName}</TableCell>
                        <TableCell>{employee.lastName}</TableCell>
                        <TableCell>{employee.email}</TableCell>
                        <TableCell>
                            <Box display='flex' alignItems='center'>
                                <Select
                                    value={mappingSelections[employee.id] || ''}
                                    onChange={event =>
                                        handleMappingChange(
                                            employee.id,
                                            event.target.value as MappingRef
                                        )
                                    }
                                    displayEmpty
                                    style={{
                                        minWidth: 150,
                                        marginRight: 8,
                                    }}
                                >
                                    <MenuItem value=''>
                                        <em>Mapping auswählen</em>
                                    </MenuItem>
                                    {mappings.map(mapping => (
                                        <MenuItem
                                            key={mapping.id}
                                            value={getMappingRef(mapping)}
                                        >
                                            {mapping.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() =>
                                        handleExportClick(employee.id)
                                    }
                                    disabled={!mappingSelections[employee.id]}
                                >
                                    Exportieren
                                </Button>
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default EmployeeExportTable;
