import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Button } from '@mui/material';
import { exportEmployee, fetchEmployees, fetchSkills } from './exportApi';
import { fetchMappings } from '../glossar/skillApi';
import { useAuth } from 'react-oidc-context';
import { ContractorMapping, FullEmployee, Skill } from '../../interfaces/rest';
import EmployeeExportTable from './employeeExportTable';
import { MappingRef } from '../../interfaces/contractorMappingRef';
import SkillFilterForm from './SkillFilterForm';

export default function ExportPage(): React.ReactElement {
    const [employees, setEmployees] = useState<FullEmployee[]>([]);
    const [mappings, setMappings] = useState<ContractorMapping[]>([]);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<
        { skillName: string; experience: number }[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchClicked, setSearchClicked] = useState<boolean>(false);
    const auth = useAuth();

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                setLoading(true);
                const [mappingsData, skillsData] = await Promise.all([
                    fetchMappings(auth.user?.access_token || ''),
                    fetchSkills(auth.user?.access_token || ''),
                ]);
                setMappings(mappingsData);
                setSkills(skillsData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        loadInitialData();
    }, [auth.user?.access_token]);

    const loadEmployees = async () => {
        setLoading(true);
        try {
            const employeesData = await fetchEmployees(
                auth.user?.access_token || '',
                selectedSkills
            );

            setEmployees(employeesData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        loadEmployees();
    };

    const handleExport = async (employeeId: number, mappingRef: MappingRef) => {
        try {
            await exportEmployee(
                auth.user?.access_token || '',
                employeeId,
                mappingRef
            );
            alert('Employee exported successfully');
        } catch (error) {
            console.error(error);
            alert('Failed to export employee');
        }
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <SkillFilterForm
                skills={skills}
                selectedSkills={selectedSkills}
                setSelectedSkills={setSelectedSkills}
            />
            <Button
                variant='contained'
                color='primary'
                onClick={handleSearchClick}
                sx={{ marginBottom: 2 }}
            >
                Suchen
            </Button>
            {loading && (
                <Container sx={{ textAlign: 'center', paddingTop: 4 }}>
                    <CircularProgress />
                </Container>
            )}
            {!loading && searchClicked && (
                <EmployeeExportTable
                    employees={employees}
                    mappings={mappings}
                    onExport={handleExport}
                />
            )}
        </Container>
    );
}
