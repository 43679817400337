import React from 'react';
import { Box, Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { Skill } from '../../interfaces/rest';

interface SkillFilterFormProps {
    skills: Skill[];
    selectedSkills: {
        skillName: string;
        experience: number;
    }[];
    setSelectedSkills: React.Dispatch<
        React.SetStateAction<{ skillName: string; experience: number }[]>
    >;
}

const SkillFilterForm: React.FC<SkillFilterFormProps> = ({
    skills,
    selectedSkills,
    setSelectedSkills,
}) => {
    const handleAddSkill = () => {
        setSelectedSkills([
            ...selectedSkills,
            { skillName: '', experience: 0 },
        ]);
    };

    const handleSkillChange = (
        index: number,
        field: string,
        value: string | number
    ) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills[index] = { ...updatedSkills[index], [field]: value };
        setSelectedSkills(updatedSkills);
    };

    const handleRemoveSkill = (index: number) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills.splice(index, 1);
        setSelectedSkills(updatedSkills);
    };

    return (
        <Box mb={4}>
            <Button
                variant='contained'
                color='primary'
                onClick={handleAddSkill}
            >
                Skill-Filter hinzufügen
            </Button>
            {selectedSkills.map((skillFilter, index) => (
                <Grid
                    container
                    spacing={2}
                    alignItems='center'
                    key={index}
                    sx={{ marginTop: 2 }}
                >
                    <Grid item xs={3}>
                        {/* Skill-Auswahl */}
                        <Select
                            fullWidth
                            value={skillFilter.skillName}
                            onChange={e =>
                                handleSkillChange(
                                    index,
                                    'skillName',
                                    e.target.value
                                )
                            }
                            displayEmpty
                        >
                            <MenuItem value=''>
                                <em>Skill auswählen</em>
                            </MenuItem>
                            {skills.map(skill => (
                                <MenuItem key={skill.id} value={skill.name}>
                                    {skill.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        {/* Mindesterfahrung */}
                        <TextField
                            fullWidth
                            type='number'
                            label='Mindesterfahrung (Monate)'
                            value={skillFilter.experience}
                            onChange={e =>
                                handleSkillChange(
                                    index,
                                    'experience',
                                    parseInt(e.target.value)
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => handleRemoveSkill(index)}
                        >
                            Entfernen
                        </Button>
                    </Grid>
                </Grid>
            ))}
        </Box>
    );
};

export default SkillFilterForm;
