import React, { useState } from 'react';
import { Tabs, Tab, Box, Container } from '@mui/material';
import GlossaryTable from './glossaryTable';
import ContractorMappingTable from './contractorMappingTable';

interface GlossaryProps {
    isSalesUser: boolean;
}

export default function Table({
    isSalesUser,
}: GlossaryProps): React.ReactElement {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor='primary'
                textColor='primary'
                centered
            >
                <Tab label='Glossary' />
                <Tab label='Contractor Mapping' />
            </Tabs>
            <Box sx={{ paddingTop: 4 }}>
                {selectedTab === 0 ? (
                    <GlossaryTable isSalesUser={isSalesUser} />
                ) : (
                    <ContractorMappingTable />
                )}
            </Box>
        </Container>
    );
}
